import React from "react";
import katex from "katex";
import "katex/dist/katex.min.css";

const MathRenderer = ({ expression }) => {
  const html = katex.renderToString(expression, {
    throwOnError: false,
  });

  return <span dangerouslySetInnerHTML={{ __html: html }} />;
};

export default MathRenderer;
