import React from "react";
import Latex from "react-latex-next";
import "../styles/AsymptoticGameStyle.css"; // Import the CSS file

const HowToPlay = () => {
  return (
    <div className="info-area">
      <h2>How to Play</h2>

      {/* Step 1 */}
      <div className="step-card">
        <h3 className="step-title">1. Understand the Challenge</h3>
        <p className="step-description">
          <Latex>
            This game challenges you to identify the correct asymptotic
            relationship between two terms: $term1$ and $term2$.
          </Latex>
        </p>
      </div>

      {/* Step 2 */}
      <div className="step-card">
        <h3 className="step-title">2. Examine the Expression</h3>
        <p className="step-description">
          <Latex>
            The relationship is represented as: $term1 \in \ ? (term2)$. Your
            goal is to determine which mathematical relationship (e.g., $O$,
            $\omega$, $\Theta$) fits the given terms.
          </Latex>
        </p>
      </div>

      {/* Step 3 */}
      <div className="step-card">
        <h3 className="step-title">3. Select Your Answer</h3>
        <p className="step-description">
          From the list of options provided, select the correct asymptotic
          relationship. Click <b>Submit</b> to check if your choice is correct.
        </p>
      </div>

      {/* Step 4 */}
      <div className="step-card">
        <h3 className="step-title">4. Continue Practicing</h3>
        <p className="step-description">
          After submitting your answer, click <b>Next</b> to try another
          challenge. Keep practicing to improve your understanding of asymptotic
          analysis!
        </p>
      </div>
    </div>
  );
};

export default HowToPlay;
