import React, { useState } from "react";
import MathRenderer from "../../utils/MathRenderer"; // Adjust the path if the file is in a different folder
import HowToPlay from "./components/HowToPlay";
import TheScienceBehind from "./components/TheScienceBehind";
import "katex/dist/katex.min.css"; // Include Katex CSS for styling
import "./styles/AsymptoticGameStyle.css";

// Available terms and relationships
const terms = ["1", "log(n)", "n", "n log(n)", "n^2", "2^n", "n!"];
const relationships = [
  { value: "Little o", label: "Little o", mathSymbol: "o" },
  { value: "Big O", label: "Big O", mathSymbol: "O" },
  { value: "Little Omega", label: "Little omega", mathSymbol: "\\omega" },
  { value: "Big Omega", label: "Big Omega", mathSymbol: "\\Omega" },
  { value: "Theta", label: "Theta", mathSymbol: "\\Theta" },
  {
    value: "Asymptotic Equivalence",
    label: "Equivalence",
    mathSymbol: "\\sim",
  },
];

const Game = () => {
  const [gameStarted, setGameStarted] = useState(false);
  const [term1, setTerm1] = useState(terms[0]);
  const [term2, setTerm2] = useState(terms[1]);
  const [selectedRelationships, setSelectedRelationships] = useState([]);
  const [feedback, setFeedback] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState("submit"); // 'submit' or 'next'

  const resetGame = () => {
    const newTerm1 = terms[Math.floor(Math.random() * terms.length)];
    let newTerm2 = terms[Math.floor(Math.random() * terms.length)];
    while (newTerm1 === newTerm2) {
      newTerm2 = terms[Math.floor(Math.random() * terms.length)];
    }
    setTerm1(newTerm1);
    setTerm2(newTerm2);
    setSelectedRelationships([]);
    setFeedback({});
    setError(null);
    setLoading(false);
    setStage("submit");
  };

  const handleSelectRelationship = (relationship) => {
    setSelectedRelationships((prev) =>
      prev.includes(relationship)
        ? prev.filter((rel) => rel !== relationship)
        : [...prev, relationship]
    );
  };

  const handleSubmit = async () => {
    if (stage === "next") {
      resetGame();
      return;
    }

    setLoading(true);
    setError(null);
    setFeedback({});

    try {
      // Dynamically determine the API base URL
      const baseUrl = process.env.REACT_APP_API_BASE_URL;

      const response = await fetch(`${baseUrl}/api/relationship`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ term1, term2 }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || "Failed to fetch data from the server"
        );
      }

      const data = await response.json();
      console.log("Backend Response:", data);

      const correct = data.relationships || [];

      const newFeedback = {};
      relationships.forEach(({ value }) => {
        if (selectedRelationships.includes(value)) {
          if (correct.includes(value)) {
            newFeedback[value] = "green";
          } else {
            newFeedback[value] = "red";
          }
        } else if (correct.includes(value)) {
          newFeedback[value] = "lightgreen";
        } else {
          newFeedback[value] = "";
        }
      });
      setFeedback(newFeedback);
      setStage("next");
    } catch (err) {
      console.error("Error:", err.message);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div
          className={`game-grid ${
            gameStarted ? "game-started" : "not-started"
          }`}
        >
          {!gameStarted ? (
            <div className="header">
              <h1 className="title">Asymptotic Analysis Trainer</h1>
              <p className="description">
                Learn, practice, and master asymptotic analysis with this
                interactive game.
              </p>
              <button
                className="start-button"
                onClick={() => setGameStarted(true)}
              >
                Start Game
              </button>
            </div>
          ) : (
            <div className="game-area">
              {/* Main Content: Grouped Terms and Relationships */}
              <div className="main-content">
                {/* Title */}
                <h2 className="game-title">
                  Choose the correct asymptotic relationship for:
                </h2>

                {/* Terms and Relationship Placeholder */}
                <div className="terms-container">
                  <span className="term-box">
                    <MathRenderer expression={term1} />
                  </span>
                  <div>
                    <span className="relationship-symbol">
                      <MathRenderer expression="\in " />
                    </span>
                    <span className="placeholder"> ?</span>
                  </div>
                  <span className="term-box">
                    (<MathRenderer expression={term2} />)
                  </span>
                </div>

                {/* Relationships Buttons */}
                <div className="relationships-container">
                  {relationships.map(({ value, label, mathSymbol }) => (
                    <button
                      key={value}
                      onClick={() => handleSelectRelationship(value)}
                      className={`relationship-button ${
                        selectedRelationships.includes(value) ? "selected" : ""
                      }`}
                      style={{
                        backgroundColor: feedback[value] || undefined,
                      }}
                      title={label} // Tooltip for additional explanation
                    >
                      <span className="math-symbol">
                        <MathRenderer expression={mathSymbol} />
                      </span>
                    </button>
                  ))}
                </div>

                {/* Submit Button */}
                <button
                  onClick={handleSubmit}
                  className={`submit-button ${loading ? "loading" : ""}`}
                  disabled={loading}
                >
                  {loading
                    ? "Loading..."
                    : stage === "submit"
                    ? "Submit"
                    : "Next"}
                </button>

                {/* Error Message */}
                {error && (
                  <div className="error" role="alert">
                    Error: {error}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Additional Content: How to Play & Statistics */}
        <div className="additional-content">
          <div className="info-grid">
            {/* How to Play Section */}
            <HowToPlay />

            {/* The Science Behind Asymptotic Analysis Section */}
            <TheScienceBehind />
          </div>
        </div>
      </div>
    </>
  );
};

export default Game;
