import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Menu from "./components/layout/Menu";
import AsymptoticOrder from "./pages/AsymptoticGame/AsymptoticGame";

function App() {
  return (
    <Router>
      <div className="App">
        <Menu />
        <Routes>
          <Route path="/Asymptotic-Order" element={<AsymptoticOrder />} />
          {/* Add more routes here if needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
