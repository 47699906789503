import React from "react";
import Latex from "react-latex-next";
import "../styles/AsymptoticGameStyle.css"; // Import the CSS file

// Data Structure for Asymptotic Analysis
const asymptoticData = [
  {
    title: "Big O",
    symbol: "O",
    description: "Describes an upper bound.",
    formalDefinition: "f(n) \\in O(g(n))",
    condition1: "\\exists c > 0 \\text{ and } n_0 \\geq 0",
    condition2: "|f(n)| \\leq c \\cdot |g(n)| \\text{ for all } n \\geq n_0",
    explanation:
      "This means that for large values of $n$, the function $f(n)$ does not grow faster than $g(n)$ up to a constant factor.",
  },
  {
    title: "Little o",
    symbol: "o",
    description: "Describes a stricter upper bound.",
    formalDefinition: "f(n) \\in o(g(n))",
    condition1: "\\forall c > 0: \\exists n_0 \\geq 0",
    condition2: "|f(n)| < c \\cdot |g(n)| \\text{ for all } n \\geq n_0",
    explanation:
      "This means that for large values of $n$, the function $f(n)$ grows strictly slower than $g(n)$ up to a constant factor.",
  },
  {
    title: "Big Omega",
    symbol: "\\Omega",
    description: "Describes a lower bound.",
    formalDefinition: "f(n) \\in \\Omega(g(n))",
    condition1: "\\exists c > 0 \\text{ and } n_0 \\geq 0",
    condition2: "|f(n)| \\geq c \\cdot |g(n)| \\text{ for all } n \\geq n_0",
    explanation:
      "This means that for large values of $n$, the function $f(n)$ grows at least as fast as $g(n)$ up to a constant factor.",
  },
  {
    title: "Little Omega",
    symbol: "\\omega",
    description: "Describes a strict lower bound.",
    formalDefinition: "f(n) \\in \\omega(g(n))",
    condition1: "\\forall c > 0, \\exists n_0 \\geq 0",
    condition2: "|f(n)| > c \\cdot |g(n)| \\text{ for all } n \\geq n_0",
    explanation:
      "This means that for large values of $n$, the function $f(n)$ grows strictly faster than $g(n)$ up to a constant factor.",
  },
  {
    title: "Theta",
    symbol: "\\Theta",
    description: "Represents a tight bound.",
    formalDefinition: "f(n) \\in \\Theta(g(n))",
    condition1: "\\exists c_1 > 0, c_2 > 0 \\text{ and } n_0 \\geq 0",
    condition2:
      "c_1 \\cdot |g(n)| \\leq |f(n)| \\leq c_2 \\cdot |g(n)| \\text{ for all } n \\geq n_0",
    explanation:
      "This means that for large values of $n$, the functions $f(n)$ and $g(n)$ grow at the same rate up to constant factors.",
  },
  {
    title: "Equivalence",
    symbol: "\\sim",
    description: "Describes functions that grow at the same rate.",
    formalDefinition: "\\lim_{n \\to \\infty} \\frac{f(n)}{g(n)} = 1",
    condition1: "",
    condition2: "",
    explanation:
      "This means that for large values of $n$, the ratio of $f(n)$ to $g(n)$ becomes arbitrarily close to 1.",
  },
];

const TheScienceBehind = () => {
  const toggleCard = (e) => {
    const cardBody = e.currentTarget.nextElementSibling;
    cardBody.classList.toggle("card-body-expanded");
  };

  return (
    <div className="info-area">
      <h2>The Science Behind Asymptotic Analysis</h2>
      {asymptoticData.map(
        (
          {
            title,
            symbol,
            description,
            formalDefinition,
            condition1,
            condition2,
            explanation,
          },
          index
        ) => (
          <div key={index} className="card">
            <div className="card-header" onClick={toggleCard}>
              <div>
                <b>{title}</b>
                <Latex>{` $(${symbol})$`}</Latex>
              </div>
              <p>{description}</p>
            </div>
            <div className="card-body">
              <p>
                <b>Formally:</b> <Latex>{`$${formalDefinition}$`}</Latex>
              </p>
              {condition1 && (
                <p>
                  <b>If:</b> <Latex>{`$${condition1}$`}</Latex>
                </p>
              )}
              {condition2 && (
                <p>
                  <b>Such that:</b> <Latex>{`$${condition2}$`}</Latex>
                </p>
              )}
              <p>
                <b>Explanation:</b> <Latex>{`${explanation}`}</Latex>
              </p>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default TheScienceBehind;
