// Menu.js
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Menu2.css"; // Import the external CSS

const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle the menu open state
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close the menu when a link is clicked (useful for single-page applications)
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="nav">
      {/* Home Button */}
      <NavLink to="/home" className="home-button" activeClassName="active">
        Home
      </NavLink>

      {/* Hamburger Icon */}
      <button
        className={`hamburger ${isMenuOpen ? "active" : ""}`}
        aria-label="Toggle menu"
        aria-expanded={isMenuOpen}
        onClick={toggleMenu}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </button>

      {/* Menu Items */}
      <ul className={`menu-items ${isMenuOpen ? "open" : ""}`}>
        <li className="menu-item">
          <NavLink
            to="/asymptotic-order"
            className="menu-link"
            activeClassName="active"
            onClick={closeMenu}
          >
            Asymptotic Order
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink
            to="/simple-math"
            className="menu-link"
            activeClassName="active"
            onClick={closeMenu}
          >
            Simple Math
          </NavLink>
        </li>
        {/* Add more menu items here */}
      </ul>
    </nav>
  );
};

export default Menu;
